import React from 'react';
import { Box } from '@mui/material';

import { Button } from 'components/button/button';
import IconLoveHands from 'components/icon/loveHand';
import PageHeader from 'components/pageHeader/pageHeader';
import DonationTypeSwitch from 'pages/common/pledge/overlayVariant/donationTypeSwitch';
import { OrganizationInfo } from 'types/organization';
import { StocksDonationPledge } from 'types/pledge';
import DonationWorkflowType from 'types/workflow';

import { PledgeForm } from './pledgeForm';
import { LABELS } from './stockDonationPledge.keys';
import useStyles from './styles';

interface OverlayStockDonationPledgeScreenProps {
  pledge: StocksDonationPledge;
  organization: OrganizationInfo;
  updatePledge: (changes: Partial<StocksDonationPledge>) => void;
  proceed: () => void;
  isDisabledSubmit: boolean;
  isSubmitting: boolean;
  error: Record<string, string> | null;
  type: DonationWorkflowType;
  setType: (type: DonationWorkflowType) => void;
  availableFlows: DonationWorkflowType[];
  updateExchangeRate: () => void;
  isExchangeRateUnavailable: boolean;
}

const OverlayStockDonationPledgeScreen = ({
  pledge,
  organization,
  updatePledge,
  proceed,
  isDisabledSubmit,
  isSubmitting,
  error,
  type,
  setType,
  availableFlows,
  updateExchangeRate,
  isExchangeRateUnavailable,
}: OverlayStockDonationPledgeScreenProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.page}>
      <PageHeader label={LABELS.PAGE_TITLE} />
      <DonationTypeSwitch
        type={type}
        setType={setType}
        availableFlows={availableFlows}
      />
      <PledgeForm
        pledge={pledge}
        onChange={updatePledge}
        organization={organization}
        error={error}
        updateExchangeRate={updateExchangeRate}
        isExchangeRateUnavailable={isExchangeRateUnavailable}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Button
          onClick={proceed}
          disabled={isDisabledSubmit || isSubmitting}
          className={classes.nextButton}
        >
          <div className={classes.nextButtonContent}>
            <span>
              {LABELS.PROCEED_BUTTON_TEXT}
            </span>
            <IconLoveHands width={25} height={25} />
          </div>
        </Button>
      </Box>
    </div>
  );
};

export default OverlayStockDonationPledgeScreen;
