import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import { Button } from 'components/button/button';
import IconLoveHands from 'components/icon/loveHand';
import OptionallyVisible from 'components/optionallyVisible';
import PageHeader from 'components/pageHeader/pageHeader';
import Currency from 'types/currency';
import Frequency from 'types/frequency';
import { DonationPledge } from 'types/pledge';
import DonationWorkflowType from 'types/workflow';
import { locators, QALocator } from 'utils/tests/QA';

import { OrganizationWithFees } from '../pledge.types';
import DonationTypeSwitch from './donationTypeSwitch/donationTypeSwitch';
import { LABELS } from './pledge.keys';
import { PledgeForm } from './pledgeForm';
import useStyles from './styles';

interface PledgeScreenProps {
  pledge: DonationPledge;
  organization: OrganizationWithFees;
  changeFiatAmount: (value: number) => void;
  changeCryptoAmount: (value: number) => void;
  updatePledge: (changes: Partial<DonationPledge>) => void;
  fetchCurrencyExchangeRate: () => void;
  proceed: () => void;
  isDisabledSubmit: boolean;
  currencies: Currency[];
  cryptoDropdownTouched: boolean;
  setDropdownTouched: (value: boolean) => void;
  isCreditCardDonation: boolean;
  isFiatDonation: boolean;
  isSubmitting: boolean;
  error: Record<string, string> | null;
  clearErrors: () => void;
  type: DonationWorkflowType;
  setType: (type: DonationWorkflowType) => void;
  availableFlows: DonationWorkflowType[];
  frequency: Frequency;
  setFrequency: (frequency: Frequency) => void;
  areRecurringDonationsEnabled: boolean;
}

const PledgeScreen = ({
  pledge,
  organization,
  changeFiatAmount,
  changeCryptoAmount,
  updatePledge,
  fetchCurrencyExchangeRate,
  proceed,
  isDisabledSubmit,
  currencies,
  cryptoDropdownTouched,
  setDropdownTouched,
  isCreditCardDonation,
  isFiatDonation,
  isSubmitting,
  clearErrors,
  error,
  type,
  setType,
  availableFlows,
  frequency,
  setFrequency,
  areRecurringDonationsEnabled,
}: PledgeScreenProps) => {
  const { classes } = useStyles();

  useEffect(() => {
    fetchCurrencyExchangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return clearErrors;
  }, [pledge.currency]);

  return (
    <div className={classes.page} {...QALocator(locators.page.common.pledge)}>
      <PageHeader label={LABELS.PAGE_TITLE} />
      <DonationTypeSwitch
        type={type}
        setType={setType}
        availableFlows={availableFlows}
      />
      <PledgeForm
        pledge={pledge}
        enableNotes={organization.areNotesEnabled}
        onMainAmountChange={changeCryptoAmount}
        onSecondaryAmountChange={changeFiatAmount}
        onChange={updatePledge}
        currencies={currencies}
        organization={organization}
        cryptoDropdownTouched={cryptoDropdownTouched}
        setDropdownTouched={setDropdownTouched}
        isCreditCardDonation={isCreditCardDonation}
        isFiatDonation={isFiatDonation}
        error={error}
        frequency={frequency}
        setFrequency={setFrequency}
        areRecurringDonationsEnabled={areRecurringDonationsEnabled}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Button
          onClick={proceed}
          disabled={isDisabledSubmit || isSubmitting}
          className={classes.nextButton}
          {...QALocator(locators.page.common.components.nextButton)}
        >
          <div className={classes.nextButtonContent}>
            <span>
              {LABELS.PROCEED_BUTTON_TEXT}
            </span>
            <OptionallyVisible visible={!organization.isPCDEnabled}>
              <IconLoveHands width={25} height={25} />
            </OptionallyVisible>
          </div>
        </Button>
      </Box>
    </div>
  );
};

export default PledgeScreen;
