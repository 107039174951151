import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import PageHeader from 'components/pageHeader/pageHeader';

import { defaultFormValue, KEYS } from './emailConfirmation.keys';
import { SkipButton, SubmitButton } from './emailDialog.buttons';
import { EmailField } from './emailField';
import useStyles from './styles';
import { EmailDialogProps } from './types';

const EmailSchema = Yup.object().shape({
  [KEYS.EMAIL_FIELD_KEY]: Yup.string()
    .email(KEYS.EMAIL_VALIDATION_MESSAGE)
    .required(KEYS.REQUIRED_VALIDATION_MESSAGE),
});

export const EmailConfirmationDialog = ({
  toggleModalSubmitOpen,
  onSubmit,
  goBack,
}: EmailDialogProps) => {
  const { classes } = useStyles();

  return (
    <Formik
      initialValues={defaultFormValue}
      validationSchema={EmailSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <PageHeader label={KEYS.DIALOG_TITLE} withBackButton onGoBack={goBack} />
        <DialogContent classes={{ root: classes.contentContainer }}>
          <DialogContentText id="alert-dialog-description" className={classes.contentText}>
            {KEYS.DIALOG_TEXT}
          </DialogContentText>
        </DialogContent>
        <DialogContent classes={{ root: classes.contentContainer }}>
          <div className={classes.bottomContainer}>
            <EmailField
              placeholder={KEYS.CONFIRM_EMAIL_LABEL}
              className={classes.defaultInput}
              name={KEYS.EMAIL_FIELD_KEY}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <SkipButton
            type="button"
            variant="text"
            className={classes.separated}
            onClick={toggleModalSubmitOpen}
          >
            {KEYS.SKIP}
          </SkipButton>
          <SubmitButton type="submit" variant="text" color="primary" autoFocus>
            {KEYS.CONFIRM}
          </SubmitButton>
        </DialogActions>
      </Form>
    </Formik>
  );
};

export default React.memo(EmailConfirmationDialog);
