import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import thunkMiddleware from 'redux-thunk';

import reducers from './index';

export function configureStore() {
  const middlewares = [thunkMiddleware];
  const reducer = combineReducers({
    ...reducers,
  });

  // @ts-ignore
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return composeEnhancers(applyMiddleware(...middlewares))(createStore)(reducer);
}

export const store = configureStore();

export default store;
