import React, { ReactNode, useMemo } from 'react';
import { Paper } from '@mui/material';

import OptionallyVisible from 'components/optionallyVisible';
import { OrganizationInfo } from 'types/organization';
import { DonationWorkflowType, WorkflowStep } from 'types/workflow';

import { DisabledPage } from './disabled';
import { Header } from './header';
import { KEYS, LABELS } from './keys';
import { PoweredBy } from './poweredBy';
import Stages from './stages';
import useStyles from './styles';
import { PoweredByBrand, PoweredByBrandType } from './types';
import { getBrand } from './utils';

interface LayoutProps {
  children: ReactNode;
  organization: OrganizationInfo;
  step: WorkflowStep | null;
  workflowType: DonationWorkflowType;
  steps: {
    title: string;
    key: WorkflowStep;
  }[];
}

export const Layout = ({
  children,
  organization,
  step,
  steps,
  workflowType,
}: LayoutProps) => {
  const { classes } = useStyles();

  const hasSteps = step !== null;

  const brand: PoweredByBrandType = useMemo(() => getBrand({
    workflowType,
    isPCDEnabled: organization.isPCDEnabled,
  }), [workflowType, organization.isPCDEnabled]);

  if (organization?.disabled) {
    return <DisabledPage organization={organization} />;
  }

  return (
    <div className={classes.root}>
      <OptionallyVisible visible={!organization.isPCDEnabled}>
        <div className={classes.rootLogos}>
          <img src={KEYS.LOGO[PoweredByBrand.Crypto]} alt={LABELS.ALT[PoweredByBrand.Crypto]} />
          <div className={classes.rootLogosSeparator} />
          <img src={KEYS.LOGO[PoweredByBrand.Card]} alt={LABELS.ALT[PoweredByBrand.Card]} />
        </div>
      </OptionallyVisible>
      <div className={classes.container}>
        <Paper elevation={3} className={classes.paper}>
          <Header organization={organization} />
          <div className={classes.contentWrapper}>
            <OptionallyVisible visible={hasSteps}>
              <Stages
                stage={step as WorkflowStep}
                steps={steps}
              />
            </OptionallyVisible>
            {children}
          </div>
        </Paper>
      </div>
      <OptionallyVisible visible={!organization.isPCDEnabled}>
        <PoweredBy brand={brand} />
      </OptionallyVisible>
    </div>
  );
};

export default Layout;
