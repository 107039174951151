import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Broker, BrokerInfo } from 'types/broker';
import { brokerIsMorganStanley, brokerNameIsOther } from 'utils/broker';

import { getBrokerInfoFormFields, KEYS, LABELS } from './brokerInfo.keys';
import TextField from './fields/textField';
import useStyles from './styles';

const fieldSchemas = {
  email: Yup.string().email(LABELS.INVALID_EMAIL),
};

export interface BrokerInforFormProps {
  brokerInfo: BrokerInfo;
  onChange: (changes: Partial<BrokerInfo>) => void;
  brokers: Broker[];
  error: Record<string, string>;
}

export const BrokerInforForm = ({
  brokerInfo,
  onChange,
  brokers,
  error,
}: BrokerInforFormProps) => {
  const { classes } = useStyles();

  const filteredBrokerInfoFormFields = useMemo(() => {
    const isBrokerAdditionalInfoRequired = brokerIsMorganStanley(brokerInfo);

    return getBrokerInfoFormFields(isBrokerAdditionalInfoRequired).filter((field) => {
      const isBrokerNameOther = brokerNameIsOther(brokerInfo);
      const fieldIsBrokerFirm = field.name === KEYS.CUSTOM_BROKER_NAME;
      const shouldFilterOutField = !isBrokerNameOther && fieldIsBrokerFirm;
      if (shouldFilterOutField) {
        return false;
      }

      return true;
    });
  }, [brokerInfo]);

  const schemaObject = useMemo(() => filteredBrokerInfoFormFields.reduce((schema, item) => {
    const schemaType = fieldSchemas[item.type] || Yup.string();
    const requiredSchemaType = schemaType.required(LABELS.REQURED);

    return {
      ...schema,
      [item.name]: item.isRequired ? requiredSchemaType : schemaType,
    };
  }, {}), [filteredBrokerInfoFormFields]);

  const validationSchema = Yup.object().shape(schemaObject);

  const handleChange = (field: string) => (value: string | number | null) => {
    onChange({ [field]: value });
  };

  const getFieldHandler = (setFieldValue: (field: string, value: any) => void) => (field: string, value: any) => {
    handleChange(field)(value);
    setFieldValue(field, value);
  };

  return (
  // @ts-ignore
    <Formik
      initialValues={brokerInfo}
      validationSchema={validationSchema}
    >
      {({
        setFieldValue,
        isSubmitting,
      }) => (
        <Form className={classes.contentContainer}>
          <Typography className={classes.pageHeader}>
            {LABELS.BROKER_INFO_PAGE_TITLE}
          </Typography>
          {filteredBrokerInfoFormFields.map(item => (
            <TextField
              key={item.name}
              item={item}
              brokers={brokers}
              isSubmitting={isSubmitting}
              setFieldValue={getFieldHandler(setFieldValue)}
              errorText={error?.[item.name]}
            />
          ))}
        </Form>
      )}
    </Formik>
  );
};

export default BrokerInforForm;
