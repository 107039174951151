import React, {
  Ref, useContext, useEffect, useMemo, useState,
} from 'react';
import { Box } from '@mui/material';
import {
  Form, Formik, FormikErrors, FormikProps,
  FormikValues,
} from 'formik';
import * as Yup from 'yup';

import { Button, ButtonDisplayVariant } from 'components/button/button';
import { CaptchaContext } from 'components/captcha/captchaContext';
import { FormField } from 'components/formFields/overlayVariant/formField';
import { SetFieldValueFunction } from 'components/formFields/types';
import IconChevron from 'components/icon/chevron';
import IconPlaid from 'components/icon/plaid';
import Loading from 'components/loading';
import OptionallyVisible from 'components/optionallyVisible';
import PageHeader from 'components/pageHeader/pageHeader';
import COLORS from 'theme/colors';
import { DonorInfo } from 'types/donor';
import { OrganizationInfo } from 'types/organization';
import DonationWorkflowType from 'types/workflow';
import { FEATURE_KEYS, featureMap } from 'utils/config';
import prepareLine from 'utils/string';

import MandateOverlay from './components/mandateOverlay';
import { KEYS, LABELS, RETIRED_FIELD_OPTIONAL_KEYS } from './keys';
import { useStyles } from './styles';
import buildFormFields from './utils';

const fieldSchemas = {
  email: Yup.string().email(LABELS.INVALID_EMAIL),
};

interface DonorDetailsProps {
  donor: DonorInfo;
  defaultEmptyState: DonorInfo;
  organization: OrganizationInfo;
  updateValue: (field: string, value: any) => void;
  goBack: () => void;
  submitPledge: (donor: DonorInfo, captchaToken: string | null) => void;
  onProcess: () => void;
  workflowType: DonationWorkflowType;
  isSubmitting: boolean;
  pledgeError: Record<string, string> | null;
}

export const DonorInfoScreen = ({
  donor,
  defaultEmptyState,
  goBack,
  organization,
  updateValue,
  submitPledge,
  workflowType,
  isSubmitting: isSubmittingState,
  pledgeError,
  onProcess,
}: DonorDetailsProps) => {
  const { classes, cx } = useStyles();
  const [errors, setErrors] = useState({});
  const [isMandateOverlayOpen, setMandateOverlayOpen] = useState<boolean>(false);
  const { execute } = useContext(CaptchaContext);
  const formRef: Ref<FormikProps<FormikValues>> | undefined = React.createRef();
  const formFields = useMemo(() => buildFormFields({
    anonymousAllowed: false,
    areEmployerDetailsRequired: organization.areEmployerDetailsRequired,
    formValues: donor,
    workflowType,
  }), [organization.allowsAnon, donor, workflowType]);
  const formatters = useMemo(() => formFields.reduce((acc, formField) => ({
    ...acc,
    [formField.name]: formField.formatter || ((value: string) => value),
  }), {}), [formFields]);

  const schemaObject = formFields.reduce((schema, item) => {
    if (!item.isRequired) {
      return schema;
    }

    return {
      ...schema,
      [item.name]: (fieldSchemas[item.type] || Yup.string()).required(LABELS.REQUIRED),
    };
  }, {});

  useEffect(() => {
    if (!formRef.current || !pledgeError) {
      return;
    }

    const errorsWithFormattedKeys = Object.entries(pledgeError).reduce((acc, [key, value]) => {
      const formattedKey = key.charAt(0).toLowerCase() + key.slice(1);
      return ({ ...acc, [formattedKey]: value });
    }, {});

    formRef.current.setErrors(errorsWithFormattedKeys);
  }, [pledgeError, formRef.current]);

  const validationSchema = Yup.object().shape(schemaObject);

  const containerClasses = cx([
    classes.formContainer,
    !organization.allowsAnon ? classes.anonDisabled : '',
  ]);

  const getFieldHandler = (setFieldValue: SetFieldValueFunction, resetForm) => (
    fieldName: string,
    rawValue: any,
    shouldValidate?: boolean,
  ) => {
    const shouldResetForm = fieldName === KEYS.ANONYMOUS_FIELD_NAME && rawValue === true;
    const shouldResetOcupationFormValues = fieldName === KEYS.IS_RETIRED_FIELD_NAME && rawValue === true;
    if (shouldResetForm) {
      resetForm({ values: defaultEmptyState });
    }

    if (shouldResetOcupationFormValues) {
      RETIRED_FIELD_OPTIONAL_KEYS.forEach((fieldName) => {
        const defaultValue = defaultEmptyState[fieldName];
        setFieldValue(fieldName, defaultValue, shouldValidate);
        updateValue(fieldName, defaultValue);
      });
    }

    const formatter = formatters[fieldName];
    const value = formatter ? formatter(rawValue) : rawValue;

    setFieldValue(fieldName, value, shouldValidate);
    updateValue(fieldName, value);
  };

  const checkIsSubmitDisabled = (values, isValid) => {
    const isDisabledAnon = !organization.allowsAnon && donor.isAnonymous;
    const isDisabledWhenNotValid = !donor.isAnonymous && donor.email && !isValid && !donor.isDonorRetired;
    return Boolean(isDisabledAnon || isDisabledWhenNotValid);
  };

  const validatePledge = async (
    values: DonorInfo,
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setTouched,
  ) => {
    const validationResult = await validateForm(values);
    setTouched(validationResult);
    setErrors(validationResult);

    return Object.keys(validationResult);
  };

  const getCaptchaToken = async () => {
    if (workflowType !== DonationWorkflowType.Crypto) {
      return null;
    }

    const captchaToken = await execute();

    return captchaToken;
  };

  const handleSubmitPledge = (
    values: DonorInfo,
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setTouched,
  ) => async () => {
    const erroredFields = await validatePledge(values, validateForm, setTouched);
    if (erroredFields.length) {
      return;
    }

    onProcess();

    const captchaToken = await getCaptchaToken();
    submitPledge(values, captchaToken);
  };

  const openOverlay = () => {
    setMandateOverlayOpen(true);
  };

  const closeOverlay = () => {
    setMandateOverlayOpen(false);
  };

  if (isSubmittingState) {
    return <Loading text={LABELS.SUBMITTING_TEXT} />;
  }

  const mandateShort = prepareLine(LABELS.MANDATE_SHORT, {
    [KEYS.PLACEHOLDERS.ORGANIZATION_NAME]: organization.name,
  });

  const isAchMandateEnabled = featureMap.get(FEATURE_KEYS.IS_ACH_MANDATE_ENABLED) as boolean;

  return (
    <div className={classes.scrollContainer}>
      <PageHeader label={LABELS.PAGE_TITLE} withBackButton onGoBack={goBack} />
      {/* @ts-ignore */}
      <Formik
        innerRef={formRef}
        initialValues={donor}
        validationSchema={validationSchema}
        errors={errors}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          isValid,
          setFieldTouched,
          validateForm,
          setTouched,
          resetForm,
        }) => {
          const isSubmitDisabled = checkIsSubmitDisabled(values, isValid) || isSubmitting || isSubmittingState;
          const plaidIconColor = isSubmitDisabled ? COLORS.GREY : COLORS.WHITE;

          return (
            <Form>
              <MandateOverlay open={isMandateOverlayOpen} closeOverlay={closeOverlay} oganizationName={organization.name} />
              <OptionallyVisible visible={!isMandateOverlayOpen}>
                <>
                  <div className={containerClasses}>
                    {formFields.map(item => (
                      <FormField
                        key={item.name}
                        item={item}
                        values={values as DonorInfo}
                        hasPledgeError={Boolean(pledgeError)}
                        isSubmitting={isSubmitting}
                        setFieldValue={getFieldHandler(setFieldValue, resetForm)}
                        setFieldTouched={setFieldTouched}
                      />
                    ))}
                  </div>
                  <OptionallyVisible visible={isAchMandateEnabled}>
                    <Button display={ButtonDisplayVariant.text} onClick={openOverlay} className={classes.mandateButton}>
                      <div className={classes.mandateGrid}>
                        <div className={classes.mandateText}>{mandateShort}</div>
                        <IconChevron width={12} color={COLORS.PRIMARY} />
                      </div>
                    </Button>
                  </OptionallyVisible>
                </>
              </OptionallyVisible>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <Button
                  onClick={handleSubmitPledge(values as DonorInfo, validateForm, setTouched)}
                  disabled={isSubmitDisabled}
                  className={classes.plaidButton}
                >
                  {LABELS.PLAID_BUTTON_TEXT}
                  <IconPlaid color={plaidIconColor} className={classes.plaidLogo} />
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DonorInfoScreen;
