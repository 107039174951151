import React from 'react';

import { KEYS, LABELS } from '../keys';
import { PoweredByBrand, PoweredByBrandType } from '../types';
import useStyles from './styles';

export interface FooterProps {
  brand: PoweredByBrandType;
}

export const Footer = ({
  brand = PoweredByBrand.Crypto,
}: FooterProps) => {
  const { cx, classes } = useStyles({ isOverlay: true });

  const alt = LABELS.ALT[brand];
  const imageSrc = KEYS.LOGO[brand];
  const link = KEYS.LINK[brand];
  const footerClassMap = {
    [PoweredByBrand.Crypto]: classes.cryptoFooter,
    [PoweredByBrand.Card]: classes.shift4Footer,
    [PoweredByBrand.Gemini]: classes.geminiFooter,
  };

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={cx(classes.footer, footerClassMap[brand])}
      href={link}
    >
      <span>
        {LABELS.POWERED_BY}
      </span>
      <img src={imageSrc} alt={alt} />
    </a>
  );
};

export default Footer;
