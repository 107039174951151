import { makeStyles, withStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ isOpen?: boolean; withOptions?: boolean }>()((theme, { isOpen }) => ({
  longView: {
    gridColumn: '1 / span 2',
  },
  anonDisabled: {},
  subscriptionText: {
    fontFamily: 'Noto Sans',
    display: 'inline-block',
    color: '#2D2E31',
    lineHeight: '20px',
    fontSize: 14,
    cursor: 'pointer',
  },
  subscriptionTextDisabled: {
    color: `${theme.palette.primary.light} !important`,
    opacity: '0.5 !important',
  },
  checkboxContainerAnonim: {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default',
  },
  checkboxContainer: {
    '& .MuiCheckbox-root': {
      color: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      padding: 0,
      marginRight: 0,
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
    border: `1px solid ${theme.palette.divider}`,
    padding: '15px 20px',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 15,
    gridColumn: '1 / span 2',
  },
  disabledCheckboxContainer: {
    background: theme.palette.action.disabled,
  },
  cleanCheckboxContainer: {
    padding: 0,
    paddingTop: 5,
    borderWidth: 0,
  },
  shortInput: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 5px)',
      marginRight: 5,
    },
  },
  errorHelperText: {
    color: `${theme.palette.error.main} !important`,
  },
  defaultInput: {
    '& .MuiInputBase-input': {
      fontSize: 16,
      lineHeight: '23px',
      color: theme.palette.text.primary,
      fontFamily: 'Noto Sans',
      padding: '13px 20px !important',
      '&::placeholder': {
        color: `${theme.palette.primary.light} !important`,
        opacity: 1,
      },
      '&.Mui-disabled': {
        background: theme.palette.action.disabled,
        '&::placeholder': {
          opacity: 0.5,
        },
      },
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.divider}`,
      borderColor: `${theme.palette.divider} !important`,
    },
    [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline`]: {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.light} !important`,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 1,
      marginBottom: 1,
    },
  },
  formItem: {
    marginBottom: 10,
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 9px) scale(1)',
      fontSize: 16,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& fieldset': {
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 16,
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 5,
      marginBottom: 1,
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Poppins',
      fontWeight: 600,
      color: theme.palette.text.primary,
      margin: 0,
      marginTop: 5,
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  autocompleteRoot: {
    '&.Mui-disabled': {
      background: theme.palette.action.disabled,
      border: `1px solid ${theme.palette.divider}`,
      '&::placeholder': {
        opacity: 0.5,
      },
    },
  },
  autocompleteInputRoot: {
    height: 50,
    padding: '13px 20px !important',
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.secondary.main} !important`,
    },
    [`& .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
      border: isOpen
        ? `2px solid ${theme.palette.secondary.main} !important`
        : `1px solid  ${theme.palette.divider} !important`,
    },
    '&.Mui-disabled': {
      background: theme.palette.action.disabled,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  autocompleteInput: {
    '& .MuiInputBase-root': {
      padding: '0 !important',
    },
    '& .MuiInputBase-input': {
      fontSize: 16,
      lineHeight: '23px',
      color: theme.palette.text.primary,
      fontFamily: 'Noto Sans',
      padding: '13px 20px !important',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginRight: 20,
      '&::placeholder': {
        color: `${theme.palette.primary.light} !important`,
        opacity: 1,
      },
      '&.Mui-disabled::placeholder': {
        opacity: '0.5 !important',
      },
    },
    '& .MuiAutocomplete-inputRoot input': {
      fontSize: 16,
      fontFamily: 'Noto Sans',
      padding: '13px 20px !important',
    },
    [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-error .MuiOutlinedInput-notchedOutline`]: {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.error.light} !important`,
    },
  },
  option: {
    fontSize: 16,
    lineHeight: '23px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    fontFamily: 'Noto Sans',
    padding: '8px 18px !important',
  },
  listPopper: {
    '& .MuiAutocomplete-paper': {
      marginTop: 0,
      borderRadius: '0 0 4px 4px',
    },
  },
  listBox: {
    border: `2px solid ${theme.palette.secondary.main}`,
    maxHeight: 200,
  },
}));

const checkboxStyles = {
  root: {
    '&$checked': {
      fontSize: '10px',
      lineHeight: '16px',
      color: '#000',
    },
  },
  checked: {},
};

export const withCheckboxStyles = Component => withStyles(Component, checkboxStyles);

export default useStyles;
