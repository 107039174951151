import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  page: {
    padding: '30px 30px 45px 30px',
  },
  pageTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 25,
    lineHeight: '35px',
    textAlign: 'center',
    color: '#291B4F',
    marginTop: 8,
    marginBottom: 20,
  },
  container: {
    width: 100,
  },
  dialogPaper: {
    width: 260,
    background: 'non',
  },
  modalContainer: {
    overflow: 'hidden',
    bottomLeftBorderRadius: 8,
    bottomRightBorderRadius: 8,
  },
  formHelperText: {
    marginLeft: 74,
  },
  iconExchangeWrapper: {
    width: '16px',
    height: '16px',
    background: '#FFFFFF',
    border: '1px solid #DADFE5',
    boxSizing: 'border-box',
    borderRadius: '50%',
    display: 'flex',
    color: '#86909F',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    cursor: 'pointer',
    zIndex: '999',
  },
  contentContainerPadded: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },
  fieldHeader: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 4,
    marginTop: 10,
    width: '100%',
    textAlign: 'left',
    color: '#aaa',
  },
  swapBox: {
    padding: '0 8px',
    height: '100%',
    cursor: 'pointer',
  },
  activeCurrency: {
    fontSize: 12,
  },
  calculatedCurrency: {
    fontSize: 12,
    color: '#86909F',
  },
  inputWrapper: {
    display: 'flex',
    width: 220,
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    fontFamily: 'DS Sans',
    textAlign: 'left',
    maxWidth: 152,
    display: 'inline-block',
    paddingLeft: 8,
  },
  absolute: {
    position: 'absolute',
  },
  mainCurrencyInput: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '22px',
    padding: '0!important',
    display: 'inline',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  secondaryCurrencyInput: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '22px',
    padding: '0!important',
    display: 'inline',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subPrice: {
    color: '#86909F',
    paddingRight: 0,
    fontSize: 14,
    maxWidth: 152,
    marginRight: '8px',
    overflow: 'hidden',
    textAlign: 'left',
    width: '100%',
    paddingLeft: 8,
  },
  currency: {
    position: 'absolute',
    right: -30,
    top: -5,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '15px',
    fontFamily: 'Roboto',
    lineHeight: '48px',
    textTransform: 'uppercase',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: '#000',
    display: 'inline',
    '> .MuiTypographyColorTextSecondary': {
      color: '#000',
    },
  },
  iconExchange: {
    transform: 'rotate(90deg)',
    fontSize: 8,
  },
  inputButtonsWrapper: {
    width: '100%',
    marginBottom: 20,
  },
  amountBox: {
    border: '1px solid rgba(0, 0, 0, 0.27)',
    borderRadius: 4,
    width: 220,
    boxSizing: 'border-box',
    padding: 8,
    '&.error': {
      border: '1px solid #F02B2B',
    },
  },
  tooltip: {
    backgroundColor: '#333!important',
    border: '0!important',
    fontSize: '13px!important',
    fontFamily: 'Roboto!important',
    fontWeight: '500!important',
    lineHeight: '16px!important',
    marginTop: '6px!important',
    marginLeft: '15px!important',
  },
  tooltipArrow: {
    left: '5px!important',
    '&::before': {
      backgroundColor: 'transparent!important',
      borderLeft: '8px solid #333',
      borderBottom: '8px solid #333',
      borderTop: '8px solid transparent',
      borderRight: '8px solid transparent',
      transform: 'none!important',
      width: '0px!important',
      height: '0px!important',
    },
  },
  checkboxLabel: {
    fontSize: '14px',
    fontFamily: 'Noto Sans',
  },
  checkboxLabelTooltip: {
    color: '#888',
  },
  checkboxContainer: {
    width: '100%',
    marginLeft: 4,
    marginBottom: 11,
    marginTop: -10,

    '& .MuiFormControlLabel-root': {
      marginRight: 'unset',
    },
    '& .MuiSvgIcon-root path': {
      fill: 'transparent',
    },
  },
  nextButton: {
    flex: 1,
    height: 50,
    fontSize: 16,
    borderRadius: `${theme.shape.borderRadius} !important`,
    color: `${theme.palette.primary.main} !important`,
    lineHeight: '24px',
    background: `${theme.palette.secondary.main} !important`,
    boxShadow: 'none !important',
    textTransform: 'none',
    '&:disabled, &.Mui-disabled': {
      border: 0,
      background: `${theme.palette.action.disabledBackground} !important`,
    },
  },
  nextButtonContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
