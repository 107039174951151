import React, {
  Ref,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Container } from '@mui/material';
import {
  Form, Formik, FormikErrors, FormikProps, FormikValues,
} from 'formik';
import * as Yup from 'yup';

import { BottomButtons } from 'components';
import { CaptchaContext } from 'components/captcha/captchaContext';
import CaptchaTermsAndConditions from 'components/captcha/termsAndConditions';
import { FormField } from 'components/formFields/formField';
import { SetFieldValueFunction } from 'components/formFields/types';
import OptionallyVisible from 'components/optionallyVisible';
import EmailDialog from 'pages/common/donate/emailDialog';
import { DonorInfo } from 'types/donor';
import { OrganizationInfo } from 'types/organization';
import DonationWorkflowType from 'types/workflow';

import { KEYS, LABELS } from './keys';
import { useStyles } from './styles';
import buildFormFields from './utils';

const fieldSchemas = {
  email: Yup.string().email(LABELS.INVALID_EMAIL),
};

interface DonorDetailsProps {
  donor: DonorInfo;
  defaultEmptyState: DonorInfo;
  organization: OrganizationInfo;
  updateValue: (field: string, value: any) => void;
  goBack: () => void;
  submitPledge: (donor: DonorInfo, captchaToken: string | null) => Promise<void>;
  onProcess: () => void;
  workflowType: DonationWorkflowType;
  isSubmitting: boolean;
  fallbackAddressUsed: boolean;
  pledgeError: Record<string, string> | null;
  isRecurringDonation: boolean;
}

export const DonorInfoScreen = ({
  donor,
  defaultEmptyState,
  goBack,
  organization,
  updateValue,
  submitPledge,
  workflowType,
  isSubmitting,
  fallbackAddressUsed,
  pledgeError,
  isRecurringDonation,
  onProcess,
}: DonorDetailsProps) => {
  const { classes, cx } = useStyles();
  const [errors, setErrors] = useState({});
  const [isModalSubmitOpen, setModalSubmitOpen] = useState<boolean>(false);
  const { execute } = useContext(CaptchaContext);
  const formRef: Ref<FormikProps<FormikValues>> | undefined = React.createRef();
  const isAnonnymousDonationAllowed = organization.allowsAnon && !isRecurringDonation;
  const formFields = useMemo(() => buildFormFields({
    anonymousAllowed: isAnonnymousDonationAllowed,
    formValues: donor,
    workflowType,
  }), [
    isAnonnymousDonationAllowed,
    donor,
    workflowType,
  ]);
  const formatters = useMemo(() => formFields.reduce((acc, formField) => ({
    ...acc,
    [formField.name]: formField.formatter || ((value: string) => value),
  }), {}), [formFields]);

  const schemaObject = formFields.reduce((schema, item) => {
    if (!item.isRequired) {
      return schema;
    }

    return {
      ...schema,
      [item.name]: (fieldSchemas[item.type] || Yup.string()).required(LABELS.REQUIRED),
    };
  }, {});

  useEffect(() => {
    if (!formRef.current || !pledgeError) {
      return;
    }

    const errorsWithFormattedKeys = Object.entries(pledgeError).reduce((acc, [key, value]) => {
      const formattedKey = key.charAt(0).toLowerCase() + key.slice(1);
      return ({ ...acc, [formattedKey]: value });
    }, {});

    formRef.current.setErrors(errorsWithFormattedKeys);
  }, [pledgeError, formRef.current]);

  const validationSchema = Yup.object().shape(schemaObject);
  const isStockDonation = workflowType === DonationWorkflowType.Stock;
  const shouldApplyMoreSpaceStyle = !isStockDonation && !isAnonnymousDonationAllowed;

  const containerClasses = cx([
    classes.scrollContainer,
    shouldApplyMoreSpaceStyle ? classes.anonDisabled : '',
  ]);

  const getFieldHandler = (setFieldValue: SetFieldValueFunction, resetForm) => (
    fieldName: string,
    rawValue: any,
    shouldValidate?: boolean,
  ) => {
    const shouldResetForm = fieldName === KEYS.ANONYMOUS_FIELD_NAME && rawValue === true;
    if (shouldResetForm) {
      resetForm({ values: defaultEmptyState });
    }

    const formatter = formatters[fieldName];
    const value = formatter ? formatter(rawValue) : rawValue;

    setFieldValue(fieldName, value, shouldValidate);
    updateValue(fieldName, value);
  };

  const isSubmitDisabled = (values, isValid) => {
    const isDisabledAnon = !isAnonnymousDonationAllowed && donor.isAnonymous;
    const isDisabledWhenNotValid = !donor.isAnonymous && donor.email && !isValid;
    return Boolean(isDisabledAnon || isDisabledWhenNotValid);
  };

  const getCaptchaToken = async () => {
    if (workflowType !== DonationWorkflowType.Crypto) {
      return null;
    }

    const captchaToken = await execute();

    return captchaToken;
  };

  const handleSubmitPledge = async (
    values: DonorInfo,
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setTouched,
  ) => {
    setModalSubmitOpen(false);
    const erroredFields = await validatePledge(values, validateForm, setTouched);
    if (!erroredFields.length) {
      const captchaToken = await getCaptchaToken();

      submitPledge(values, captchaToken);
    }
  };

  const validatePledge = async (
    values: DonorInfo,
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setTouched,
  ) => {
    const validationResult = await validateForm(values);
    setTouched(validationResult);
    setErrors(validationResult);

    return Object.keys(validationResult);
  };

  const handleSubmit = (
    values: DonorInfo,
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setTouched,
  ) => {
    const shouldShowReceiptDialog = fallbackAddressUsed || values.isAnonymous;
    onProcess();
    if (shouldShowReceiptDialog) {
      setModalSubmitOpen(true);
      return;
    }

    handleSubmitPledge(values, validateForm, setTouched);
  };

  const handleModalSubmit = async (
    receiptEmail: string,
    values: DonorInfo,
  ) => {
    const captchaToken = await getCaptchaToken();

    submitPledge({ ...values, receiptEmail }, captchaToken);
    setModalSubmitOpen(false);
  };

  return (
    // @ts-ignore
    <Formik
      innerRef={formRef}
      initialValues={donor}
      validationSchema={validationSchema}
      errors={errors}
    >
      {({
        values,
        setFieldValue,
        isValid,
        setFieldTouched,
        validateForm,
        setTouched,
        errors,
        resetForm,
      }) => (
        <Form>
          <Container>
            <div className={containerClasses}>
              {formFields.map(item => (
                <FormField
                  key={item.name}
                  item={item}
                  values={values as DonorInfo}
                  isSubmitting={isSubmitting}
                  setFieldValue={getFieldHandler(setFieldValue, resetForm)}
                  setFieldTouched={setFieldTouched}
                  workflowType={workflowType}
                />
              ))}
            </div>
            <OptionallyVisible visible={workflowType === DonationWorkflowType.Crypto}>
              <CaptchaTermsAndConditions className={classes.captchaTerms} />
            </OptionallyVisible>
          </Container>
          <EmailDialog
            isModalSubmitOpen={isModalSubmitOpen}
            onSubmit={({ receiptEmail }) => handleModalSubmit(receiptEmail, values as DonorInfo)}
            toggleModalSubmitOpen={() => handleSubmitPledge(values as DonorInfo, validateForm, setTouched)}
            disable={false}
            workflowType={workflowType}
          />
          <BottomButtons
            isDisabledSubmit={isSubmitDisabled(values, isValid)}
            onClickLeft={goBack}
            onClickRight={() => handleSubmit(values as DonorInfo, validateForm, setTouched)}
            rightButtonText={LABELS.RIGHT_BUTTON_TEXT}
            leftButtonText={LABELS.LEFT_BUTTON_TEXT}
            rightButtonLoading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default DonorInfoScreen;
