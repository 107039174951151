import { makeStyles } from 'tss-react/mui';

import FORM_SIZE from 'constants/window';
import COLORS from 'theme/colors';
import FONT_FAMILIES from 'theme/fontFamilies';

const useStyles = makeStyles<{ isOverlay?: boolean }>()((theme, { isOverlay }) => ({
  rootLogos: {
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    top: 30,
    left: 30,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& img:nth-of-type(1)': {
      minHeight: 30,
    },
    '& img:nth-of-type(2)': {
      minHeight: 20,
    },
  },
  rootLogosSeparator: {
    borderLeft: `1px solid ${COLORS.GREY}`,
    margin: '0 20px',
  },
  outerContainer: {
    position: 'relative',
    maxWidth: FORM_SIZE.VERSION_2.WIDTH,
    width: '100vw',
    minWidth: 280,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: isOverlay ? 'center' : 'flex-start',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      height: `min(${FORM_SIZE.VERSION_2.HEIGHT}px, 100vh)`,
    },
    [`@media (max-height: ${FORM_SIZE.VERSION_2.HEIGHT}px)`]: {
      '#root': {
        alignItems: 'flex-start',
      },
    },
    '& + div': {
      maxWidth: FORM_SIZE.VERSION_2.WIDTH,
    },
  },
  innerContainer: {
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    width: '100%',
    height: 36,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    border: `1px solid ${COLORS.LIGHT_GREY}`,
    textDecoration: 'none',
  },
  cryptoFooter: {
    backgroundColor: COLORS.GREY_BACKGROUND,
    '& > img': {
      height: 20,
    },
    '& > span': {
      color: '#929292',
      marginRight: 6,
      fontSize: 12,
      fontWeight: 400,
      fontFamily: FONT_FAMILIES.NOTO_SANS,
    },
  },
  shift4Footer: {
    backgroundColor: COLORS.GREY_BACKGROUND,
    '& > img': {
      height: 15,
    },
    '& > span': {
      color: '#929292',
      marginRight: 6,
      fontSize: 12,
      fontWeight: 400,
      fontFamily: FONT_FAMILIES.NOTO_SANS,
    },
  },
  geminiFooter: {
    backgroundColor: COLORS.WHITE,
    '& > img': {
      height: 16,
    },
    '& > span': {
      color: '#000000',
      marginRight: 8,
      fontFamily: FONT_FAMILIES.INTER,
      fontSize: 15,
      fontWeight: 500,
    },
  },
  contentWrapper: {
    height: 'auto',
    position: 'relative',
  },
  topBarContainer: {
    height: 58,
    width: '100%',
    fontSize: 12,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${COLORS.INPUT.BORDER}`,
  },
  charityNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    maxWidth: 370,
  },
  imageContainer: {
    width: 30,
    height: 30,
    borderRadius: 20,
    overflow: 'hidden',
    alignContent: 'center',
    flexShrink: 0,
  },
  image: {
    height: 'auto',
    width: 30,
  },
  charityName: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    cursor: 'default',
    pointerEvents: 'none',
    color: theme.palette.text.secondary,
  },
  charityNameLong: {
    fontSize: 10,
  },
  poweredContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 8,
    width: 'calc(100% - 12px)',
    margin: '0 6px',
  },
  poweredPrompt: {
    fontSize: 12,
  },
  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
    boxShadow: '0 3px 12px rgba(41, 27, 79, 0.05) !important',
    minWidth: 280,
    width: '100vw',
    maxWidth: FORM_SIZE.VERSION_2.WIDTH,
    maxHeight: '100%',
    overflowY: 'auto',
    border: `1px solid ${COLORS.INPUT.BORDER}`,
  },
}));

export default useStyles;
