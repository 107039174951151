import { createTheme, ThemeOptions } from '@mui/material';

import { DesignVersion } from 'types/workflow';

import COLORS from './colors';

export interface ThemeSettings {
  designVersion: DesignVersion;
  isPCDEnabled: boolean;
}

const getColorSchema = (themeOptions: ThemeSettings) => {
  const isV2DonationForm = themeOptions.designVersion === DesignVersion.V2;
  if (themeOptions.isPCDEnabled) {
    return ({
      backgroundPaper: '#FFFFFF',
      background: '#000000',
      primary: '#FFFFFF',
      secondary: '#000000',
      border: '#C5C5C5',
      text: '#000000',
      textSecondary: COLORS.WHITE,
      disabledBackground: '#C5C5C5',
      disabled: '#F1F1F1',
      error: '#F44336',
      placeholdersColor: '#7F7F7F',
      borderError: '#F44336',
    });
  }

  if (isV2DonationForm) {
    return ({
      backgroundPaper: '#FFFFFF',
      background: COLORS.GREY_BACKGROUND,
      primary: '#291B4F',
      secondary: '#FCD42B',
      border: COLORS.LIGHT_GREY,
      text: COLORS.TEXT.PRIMARY,
      textSecondary: COLORS.TEXT.PRIMARY,
      disabledBackground: COLORS.GREY_BACKGROUND,
      disabled: COLORS.BACKGROUND,
      error: '#291B4F',
      placeholdersColor: '#948DA7',
      borderError: '#FCD42B',
    });
  }

  return ({
    backgroundPaper: '#FFFFFF',
    background: '#24292e',
    primary: '#24292e',
    secondary: '#064c8f',
    border: COLORS.LIGHT_GREY,
    text: COLORS.TEXT.PRIMARY,
    textSecondary: COLORS.TEXT.PRIMARY,
    disabledBackground: COLORS.GREY_BACKGROUND,
    disabled: COLORS.BACKGROUND,
    error: '#291B4F',
    placeholdersColor: '#948DA7',
    borderError: '#FCD42B',
  });
};

const getBorderRadius = (isPCDEnabled: boolean) => {
  if (isPCDEnabled) {
    return 0;
  }

  return 5;
};

const DEFAULT_THEME_VARIABLES: Partial<ThemeOptions> = {
  typography: {
    // @ts-ignore
    useNextVariants: true,
  },
  colorTextSecondary: {
    color: 'red',
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
        input: {
          fontSize: 14,
          height: '1.1876em',
          paddingTop: 8,
          paddingBottom: 8,
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 12px) scale(1)',
          },
        },
      },
    },
  },
};

const light = createTheme({
  palette: {
    primary: {
      main: '#24292e',
    },
    secondary: {
      main: '#064c8f',
    },
  },
  typography: {
    // @ts-ignore
    useNextVariants: true,
  },
  colorTextSecondary: {
    color: 'red',
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
        },
        input: {
          fontSize: 14,
          height: '1.1876em',
          paddingTop: 8,
          paddingBottom: 8,
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          lineHeight: '1.1876em',
          '&:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 12px) scale(1)',
          },
        },
      },
    },
  },
});

const getTheme = (themeOptions: ThemeSettings) => {
  // TODO: Refactor the logic to not have differences for handling V1 and V2 settings.

  const isV2DonationForm = themeOptions.designVersion === DesignVersion.V2;
  if (!isV2DonationForm) {
    return createTheme(light);
  }

  const colorSchema = getColorSchema(themeOptions);
  const borderRadius = getBorderRadius(themeOptions.isPCDEnabled);

  return createTheme({
    ...DEFAULT_THEME_VARIABLES,
    palette: {
      primary: {
        main: colorSchema.primary,
        light: colorSchema.placeholdersColor,
      },
      secondary: {
        main: colorSchema?.secondary,
      },
      divider: colorSchema.border,
      text: {
        primary: colorSchema.text,
        secondary: colorSchema.textSecondary,
      },
      background: {
        default: colorSchema.background,
        paper: colorSchema.backgroundPaper,
      },
      action: {
        disabledBackground: colorSchema.disabledBackground,
        disabled: colorSchema.disabled,
      },
      error: {
        main: colorSchema?.error,
        light: colorSchema?.borderError,
      },
    },
    shape: {
      borderRadius,
    },
  });
};

export default getTheme;
